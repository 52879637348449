import React, { useRef, useState } from "react";
import { Box, Stack, Button, Typography, Alert } from "@mui/material";
import { useModal } from "mui-modal-provider";
import AddItemModal from "./AddItemModal";
import { useImportCSVMutation } from "../../api/api";
import plus from "../../images/plus.svg";
import cloud from "../../images/cloud.svg";
import ImportModal from "./ImportModal";

const AddTextBtns = (props) => {
  const [file, setFile] = useState(null);
  const { showModal } = useModal();
  const fileInputRef = useRef(null);

  const [importCSV, { isError, reset }] = useImportCSVMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    await importCSV({
      file: formData,
    });

    fileInputRef.current.value = "";
    setFile(null);
  };

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" gap="32px">
      <Button
        variant="contained"
        startIcon={<img src={plus} alt="" />}
        onClick={() =>
          showModal(AddItemModal, {
            maxWords: process.env.REACT_APP_TRAINING_TEXT_WORD_LIMIT,
            maxCharacters: props.maxCharacters,
          })
        }
      >
        Add training text
      </Button>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" alignItems="center" gap={3}>
          <Box>
            <Button variant="text" component="label" startIcon={<img src={cloud} alt="" />}>
              Import from a CSV file
              <input ref={fileInputRef} hidden type="file" onChange={(e) => setFile(e.target.files[0])} />
            </Button>
            {file ? <Typography>{file.name}</Typography> : null}
          </Box>
          {file ? (
            <Button variant="contained" type="submit">
              Upload
            </Button>
          ) : null}
        </Stack>
      </form>
      <Button
        variant="text"
        component="label"
        startIcon={<img src={cloud} alt="" />}
        onClick={() => showModal(ImportModal)}
      >
        Import from website
      </Button>
      {isError ? (
        <Alert
          severity="warning"
          onClose={() => {
            reset();
          }}
        >
          Sorry, there was a problem importing your file. Make sure it is in the right format (a CSV file containing a
          single column of text items) and contains no more than 10,000 rows. If this problem persists contact us.
        </Alert>
      ) : null}
    </Stack>
  );
};

export default AddTextBtns;
