import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken } from "../store/authSlice";
import TermsAndServices from "./TermsAndServices";
import Spinner from "./Spinner";

export const InitAuth = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((s) => s.auth.accessToken);

  const { isLoading, error, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: process.env.REACT_APP_AUTH0_SCOPE,
        });
        dispatch(setAccessToken(accessToken));
      } catch (e) {
        console.error(e.message);
      }
    };
    if (isAuthenticated) {
      getAccessToken();
    }
  }, [getAccessTokenSilently, dispatch, isAuthenticated]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    });
    return null;
  }

  if (!token) {
    return <Spinner />;
  }

  return <TermsAndServices>{props.children}</TermsAndServices>;
};
