import { Box } from "@mui/material";

const EmptyStateBox = (props) => {
  return (
    <Box
      sx={{
        maxWidth: 800,
        backgroundColor: "#F4F4F6",
        border: "3px dashed #DBDBE0",
        boxShadow: "inset 2px 6px 24px rgba(0, 0, 0, 0.02)",
        borderRadius: "12px",
        marginBottom: "32px",
        p: { xs: 2, sm: 4 },
      }}
      {...props}
    />
  );
};

export default EmptyStateBox;
