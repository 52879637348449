import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = (baseUrl) =>
  fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getState().auth.accessToken;
      if (accessToken) {
        headers.set("Authorization", "Bearer " + accessToken);
      }
      return headers;
    },
  });

export default baseQuery;
