import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";

const SidebarLink = ({ children, title, to }) => {
  return (
    <ListItemButton
      to={to}
      component={NavLink}
      sx={{
        "&.active": {
          backgroundColor: "#fff",
          borderLeft: "4px solid #6C63FF",
          borderRadius: "4px 6px 6px 4px",
          "& span": {
            color: "#34343D",
          },
          "& svg": {
            stroke: "#34343D",
          },
        },
        "&:hover": { backgroundColor: "#fff" },
        borderLeft: "4px solid transparent",
        borderRadius: "6px",
        marginBottom: "10px",
      }}
    >
      <ListItemIcon sx={{ minWidth: "auto", mr: "12px" }}>{children}</ListItemIcon>
      <ListItemText
        primary={title}
        sx={{
          "& span": {
            fontSize: 16,
            fontWeight: 600,
            color: "#605F6D",
          },
        }}
      />
    </ListItemButton>
  );
};

export default SidebarLink;
