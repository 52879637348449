import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import chatApi from "../api/chat";

const messageAdapter = createEntityAdapter({
  selectId: (chat) => chat.id,
  sortComparer: (a, b) => b.lastActivityDate - a.lastActivityDate || b.id - a.id,
});

const slice = createSlice({
  name: "message",
  initialState: messageAdapter.getInitialState({ totalPages: {} }),
  reducers: {
    chatReset: messageAdapter.removeAll,
  },
  extraReducers(builder) {
    builder.addMatcher(chatApi.endpoints.getChat.matchFulfilled, (state, action) => {
      messageAdapter.upsertMany(state, action.payload.content);
      const chatId = action.meta.arg.originalArgs.id;
      state.totalPages[chatId] = action.payload.totalPages;
    });
  },
});

export const messageSelectors = messageAdapter.getSelectors((state) => state.message);
export const { chatReset } = slice.actions;
export default slice.reducer;
