import { CircularProgress, Stack } from "@mui/material";

const Spinner = () => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ minHeight: "100vh" }}>
      <CircularProgress />
    </Stack>
  );
};

export default Spinner;
