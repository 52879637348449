import { Box, Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import logo from "../images/login-logo.png";

const LoginLayout = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      display={mobile ? "block" : "flex"}
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ backgroundColor: mobile ? "#ECECEF" : "#420078" }}
    >
      <Box
        sx={{
          maxWidth: mobile ? "100%" : "440px",
          width: "100%",
          borderRadius: "4px",
          boxShadow: mobile
            ? "none"
            : "0 5px 8px #0000000a, 0 13px 32px #0000000d",
          overflow: "hidden",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          sx={{
            backgroundColor: "#fff",
            padding: "30px 12px",
            border: "1px solid rgba(24, 23, 28, 0.15)",
          }}
        >
          <img width={40} src={logo} alt="" />
          <Typography fontSize={26} fontWeight="700">
            BrightBot
          </Typography>
        </Stack>
        <Box sx={{ backgroundColor: "#F3F3F6", padding: "48px" }}>
          <Outlet />
        </Box>
      </Box>
    </Stack>
  );
};

export default LoginLayout;
