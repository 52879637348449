import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: "inherit",
    h1: {
      fontSize: 32,
      fontWeight: 600,
      color: "#212026",
      "@media (max-width: 599.98px)": {
        fontSize: 28,
      },
    },
    sectionTitle: {
      display: "block",
      fontSize: 20,
      fontWeight: 600,
      color: "#34343D",
      marginBottom: 12,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      color: "#605F6D",
      lineHeight: "150%",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "Poppins, sans-serif",
          fontWeight: 400,
          backgroundColor: "#FAFAFB",
          color: "#605F6D",
        },
        a: {
          textDecoration: "none",
          textDecorationColor: "inherit !important",
        },
        img: {
          maxWidth: "100%",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#6C63FF",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#6C63FF",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#302842",
          backgroundColor: "#fff",
          borderRadius: "4px",
          overflow: "hidden",
          "& input": {
            padding: "12px",
            fontSize: "14px",
            "&::placeholder": {
              color: "#302842",
              opacity: 0.6,
            },
          },
          "&.MuiInputBase-root:hover fieldset": {
            borderColor: "#b7b6be",
          },
          "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderColor: "rgb(73, 0, 133)",
          },
          "&.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d32f2f",
          },
          "&.MuiInputBase-root fieldset": {
            border: "1px solid rgba(24, 23, 28, 0.15)",
            transition: "border-color .05s linear",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "& .MuiButton-startIcon": {
            flexShrink: 0,
          },
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "#fff",
            padding: "14px 32px",
            backgroundColor: "#6C63FF",
            fontWeight: 600,
            whiteSpace: "nowrap",
            border: "none",
            borderRadius: "6px",
            fontSize: 16,
            "&:hover": {
              backgroundColor: "#3F35E9",
            },
            "& .MuiButton-startIcon": {
              margin: "0 16px 0 0",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            backgroundColor: "rgba(33, 32, 38, 0.05)",
            border: "1px solid rgba(33, 32, 38, 0.2)",
            borderRadius: "6px",
            color: "#34343D",
            fontSize: 16,
            fontWeight: 500,
            padding: "9px 32px",
            "&:hover": {
              backgroundColor: "rgba(33, 32, 38, 0.075)",
              border: "1px solid rgba(33, 32, 38, 0.25)",
            },
            "&.MuiButton-sizeSmall": {
              fontSize: 14,
              fontWeight: 500,
              padding: "7.5px 16px",
              lineHeight: "initial",
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            color: "#3F35E9",
            padding: 0,
            backgroundColor: "transparent",
            fontWeight: 600,
            whiteSpace: "nowrap",
            border: "none",
            fontSize: "16px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "none",
            },
            "& .MuiButton-startIcon": {
              margin: "0 13px 0 0",
            },
          },
        },
        {
          props: { variant: "profileMenu" },
          style: {
            color: "#0014ca",
            padding: 0,
            backgroundColor: "transparent",
            fontWeight: 500,
            whiteSpace: "nowrap",
            border: "none",
            fontSize: "14px",
            textTransform: "none",
            textDecoration: "underline",
            transition: "all .15s linear",
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          },
        },
      ],
    },
  },
});

export default theme;
