import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";

export const auth0ManagementApi = createApi({
  reducerPath: "auth0ManagementApi",
  baseQuery: baseQuery(`https://${process.env.REACT_APP_AUTH0_DOMAIN}`),
  endpoints: (builder) => ({
    getUserMetadata: builder.query({
      query: ({ id }) => `/api/v2/users/${id}`,
    }),
    resetPassword: builder.mutation({
      query: ({ body }) => ({
        url: "/dbconnections/change_password",
        method: "POST",
        body: body,
        responseHandler: "text",
      }),
    }),
  }),
});

export const { useResetPasswordMutation, useGetUserMetadataQuery } = auth0ManagementApi;
