import { Button, Stack, Alert, Box, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useGetAccountQuery, useUpdateAccountMutation } from "../../api/api";
import Footer from "../../components/Footer";
import FormInputText from "../../components/FormInputText";
import HeaderTitle from "../../components/HeaderTitle";

const Settings = () => {
  const { data: account } = useGetAccountQuery(null, { refetchOnMountOrArgChange: true });

  const { control, handleSubmit, formState } = useForm({
    values: {
      companyName: account?.companyName,
      websiteUrl: account?.websiteUrl,
      helpEmail: account?.helpEmail,
      alertMeWhenNewChatCreated: account?.alertMeWhenNewChatCreated,
    },
  });

  const [updateAccount, { isSuccess }] = useUpdateAccountMutation();

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", px: { xs: 2, md: 4 } }}>
      <HeaderTitle title="Settings" />
      <p>These settings are used by the chatbot when it needs to include this information in answers</p>
      <form onSubmit={handleSubmit(updateAccount)}>
        <Stack maxWidth="400px" gap="32px">
          <FormInputText
            name="companyName"
            control={control}
            rules={{
              required: "Field can't be empty",
              minLength: {
                value: 3,
                message: "Minimum length 3 character",
              },
            }}
            label="Company or solution name"
          />
          <FormInputText
            name="websiteUrl"
            control={control}
            rules={{
              required: "Field can't be empty",
              minLength: {
                value: 3,
                message: "Minimum length 3 character",
              },
            }}
            label="Website address of company or solution"
          />
          <FormInputText
            name="helpEmail"
            control={control}
            rules={{ required: "Field can't be empty" }}
            label="Support email address"
            muiProps={{ type: "email" }}
          />
          <Box>
            <Typography color="#34343D" fontWeight={600}>
              Notifications
            </Typography>
            <Controller
              name="alertMeWhenNewChatCreated"
              control={control}
              render={({ field: props }) => (
                <FormControlLabel
                  control={<Checkbox {...props} checked={!!props.value} />}
                  label={<Typography>Alert me whenever a new chat starts</Typography>}
                />
              )}
            />
          </Box>
          <Button
            type="submit"
            size="large"
            fullWidth
            disabled={formState.isSubmitting || !formState.isDirty}
            variant="contained"
            sx={{ mt: 3, mb: 4 }}
          >
            Update
          </Button>
        </Stack>
      </form>
      {isSuccess ? (
        <Alert severity="success" sx={{ maxWidth: "400px", marginTop: "30px" }}>
          Saved successfully.
        </Alert>
      ) : null}
      <Footer />
    </Box>
  );
};

export default Settings;
