import { useCallback, useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  Button,
  Link,
  LinearProgress,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { TabUnstyled, TabsUnstyled, TabsListUnstyled } from "@mui/base";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { useDeleteChatMutation, usePostMessageMutation } from "../../api/api";
import Footer from "../../components/Footer";
import EmptyStateBox from "../../components/EmptyStateBox";
import { ReactComponent as Trash } from "../../images/trash.svg";
import { ReactComponent as TrashFilled } from "../../images/trash-filled.svg";
import { ReactComponent as SendIc } from "../../images/send.svg";
import { useGetAllChatQuery, useGetChatQuery } from "../../api/chat";
import { useDispatch, useSelector } from "react-redux";
import { chatsSelectors } from "../../store/chatSlice";
import Messages from "./Messages";

function TabPanel(props) {
  const { children, value, index, isActiveUser, messagesRef, onScroll, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      sx={{ width: "100%", height: "100%" }}
      {...other}
    >
      {value === index && (
        <Stack
          ref={messagesRef}
          onScroll={onScroll}
          flexDirection="column-reverse"
          sx={{
            position: "relative",
            maxHeight: isActiveUser ? "calc(100vh - 219px)" : "calc(100vh - 126px)",
            overflowY: "auto",
            gap: 5,
            pt: { xs: "0px", md: "30px" },
            pb: { xs: isActiveUser ? "0px" : "30px", md: "30px" },
            px: { xs: "16px", md: "32px" },
          }}
        >
          {children}
        </Stack>
      )}
    </Box>
  );
}

const Chats = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [chatsPage, setChatsPage] = useState(0);
  const [chatPageNumber, setChatPageNumber] = useState(0);
  const [adminMessage, setAdminMessage] = useState("");
  const messagesRef = useRef(null);

  const [addMessage] = usePostMessageMutation();

  const [activeChatId, setActiveChatId] = useState();
  const allChats = useSelector(chatsSelectors.selectAll);
  const activeChat = useSelector((s) => chatsSelectors.selectById(s, activeChatId));
  const totalPages = useSelector((s) => s.chat.totalPages);
  const chatTotalPages = useSelector((s) => s.message.totalPages[activeChatId]);

  const isHumanControlledChatSelected = activeChat?.humanControlled;
  const chatsRefreshInterval = isHumanControlledChatSelected ? 5000 : 30000;

  const { isLoading, isFetching: isChatsFetching } = useGetAllChatQuery(
    { page: chatsPage, chatId: activeChatId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useGetAllChatQuery(
    { page: 0, isAutoRefresh: true, chatId: activeChatId },
    {
      pollingInterval: chatsRefreshInterval,
      refetchOnMountOrArgChange: true,
    }
  );

  const { isFetching, status } = useGetChatQuery(
    {
      id: activeChatId,
      pageNumber: chatPageNumber,
    },
    { skip: !activeChatId }
  );

  useEffect(() => {
    if (!activeChatId && allChats && allChats.length > 0) {
      selectChat(allChats[0].chatUuid);
    }
  }, [allChats, activeChatId, dispatch]);

  // chat list scroll
  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
      if (chatsPage < totalPages - 1 && scrolledToBottom && !isChatsFetching) {
        setChatsPage(chatsPage + 1);
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [chatsPage, totalPages, isChatsFetching]);

  useEffect(() => {
    if (activeChat && messagesRef.current) {
      const { offsetHeight, scrollHeight, scrollTop } = messagesRef.current;
      const shouldScroll = scrollHeight <= scrollTop + offsetHeight + 100;
      if (shouldScroll) {
        messagesRef.current?.scrollTo(0, messagesRef.current.scrollHeight);
      }
    }
  }, [activeChat, status, messagesRef]);

  const [deleteChat] = useDeleteChatMutation();

  const handleChange = (event, chatId) => {
    selectChat(chatId);
  };

  const selectChat = (chatId) => {
    setActiveChatId(chatId);
    setChatPageNumber(0);
  };

  // message list scroll
  const handleScroll = useCallback(
    (event) => {
      const element = event.target;
      const scrolledToTop = element.scrollHeight <= element.scrollTop * -1 + element.offsetHeight + 100;
      if (chatPageNumber < chatTotalPages - 1 && scrolledToTop && !isFetching) {
        setChatPageNumber((prevChatsPage) => prevChatsPage + 1);
      }
    },
    [chatPageNumber, chatTotalPages, isFetching]
  );

  const deleteChatHandle = async (e, id, index) => {
    e.preventDefault();
    await deleteChat(id);
    const chatIndex = allChats[index + 1]?.chatUuid || allChats[index - 1]?.chatUuid;
    handleChange(null, chatIndex);
  };

  const TabsListStyled = styled(TabsListUnstyled)({
    width: mobile ? "100%" : "294px",
    display: "flex",
    flexDirection: "column",
    borderBottom: mobile ? "1px solid #DBDBE0" : "none",
    paddingBottom: "81px",
    paddingRight: mobile ? 0 : 32,
    gap: 16,
    "& > button": {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#F4F4F6",
      border: "none",
      borderLeft: "4px solid transparent",
      borderRadius: "6px",
      padding: "12px 16px 12px 20px",
      cursor: "pointer",
      gap: 16,
      "&:hover": {
        backgroundColor: "#fff",
        "& a": {
          display: "flex !important",
        },
        "& .time-duration": {
          display: "none",
        },
      },
    },
    "& .Mui-selected": {
      backgroundColor: "#fff",
      borderColor: "#6C63FF",
      borderRadius: "4px 6px 6px 4px",
    },
  });

  const TabUnstyledStyled = styled(TabUnstyled)(({ chat }) => {
    const needsAttention = (chat.humanControlled && chat.role === "USER") || chat.lastMessageWithEmailSentAt;
    return {
      "&::after": {
        content: needsAttention ? "''" : "none",
        position: "absolute",
        top: 8,
        left: 4,
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "#3F35E9",
      },
    };
  });
  const sendMessage = async (e, chatId) => {
    e.preventDefault();
    await addMessage({
      chatId: chatId,
      body: adminMessage,
    });
    setAdminMessage("");
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", px: { xs: 2, md: 4 } }}>
      {allChats.length > 0 ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            height: "100%",
            gap: { xs: 3, md: 0 },
            "& .MuiTabs-root": {
              borderRight: mobile ? "none" : "1px solid #DBDBE0",
            },
          }}
        >
          <TabsUnstyled variant="scrollable" value={activeChatId || ""} onChange={handleChange}>
            <Typography variant="h1" component="h1" sx={{ margin: "64px 0 32px" }}>
              Chats
            </Typography>
            <TabsListStyled>
              {allChats.map((chat, index) => (
                <TabUnstyledStyled key={chat.chatUuid} value={chat.chatUuid} chat={chat}>
                  <Typography
                    variant="body1"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                      textAlign: "left",
                    }}
                  >
                    {chat.latestText}
                  </Typography>
                  <Box sx={{ flexShrink: 0 }}>
                    <Typography
                      className="time-duration"
                      fontSize="14px"
                      fontWeight={600}
                      noWrap
                      flexShrink={0}
                      sx={{ color: "#838391", textAlign: "right" }}
                    >
                      {chat.timeSinceLastActivity}
                    </Typography>
                    <Link
                      onClick={(e) => deleteChatHandle(e, chat.chatUuid, index)}
                      sx={{
                        display: "none !important",
                        "&:hover svg": {
                          stroke: "#000",
                        },
                      }}
                    >
                      <Trash />
                    </Link>
                  </Box>
                </TabUnstyledStyled>
              ))}
              {isChatsFetching ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : null}
            </TabsListStyled>
          </TabsUnstyled>
          <Box
            sx={{
              width: "100%",
              position: "sticky",
              top: 0,
              maxHeight: "calc(100vh - 65px)",
              backgroundColor: "#fff",
              mr: -4,
            }}
          >
            {allChats.map((ch, index) => (
              <TabPanel
                key={ch.chatUuid}
                value={activeChatId}
                index={ch.chatUuid}
                isActiveUser={ch.activeUser}
                messagesRef={messagesRef}
                onScroll={handleScroll}
                sx={{
                  width: "100%",
                  border: "none",
                  pt: { xs: "0px", md: "61px" },
                }}
              >
                {ch.activeUser ? (
                  <Stack
                    component="form"
                    position={{ xs: "static", md: "fixed" }}
                    width={{ xs: "auto", md: "calc(100% - 566px)" }}
                    rigth={0}
                    bottom="65px"
                    direction="row"
                    padding={{ xs: "24px", md: "24px 48px" }}
                    borderTop="1px solid #DBDBE0"
                    bgcolor="#fff"
                    m={{ xs: "0 -16px", md: "0 0 0 -32px" }}
                  >
                    <TextField
                      fullWidth
                      value={adminMessage}
                      onChange={(e) => setAdminMessage(e.target.value)}
                      placeholder="Take over this chat..."
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton type="submit" onClick={(e) => sendMessage(e, ch.chatUuid)}>
                              <SendIc />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ paddingRight: "6px" }}
                    />
                  </Stack>
                ) : null}

                {isFetching ? (
                  <Box
                    sx={{
                      width: { xs: "calc(100% - 36px)", md: "calc(100% - 80px)" },
                      position: "absolute",
                      bottom: { xs: ch.activeUser ? "109px" : "10px", md: "10px" },
                    }}
                  >
                    <LinearProgress />
                  </Box>
                ) : null}

                <Messages activeChatId={activeChatId} />

                <Stack
                  position={{ xs: "static", md: "fixed" }}
                  width={{ xs: "auto", md: "calc(100% - 566px)" }}
                  rigth={0}
                  top={0}
                  direction="row"
                  justifyContent="flex-end"
                  zIndex={1}
                  m={{ xs: "0 -16px", md: "0 0 0 -32px" }}
                  sx={{ bgcolor: "#fff", borderBottom: "1px solid #DBDBE0", py: 2, px: 6 }}
                >
                  <Button
                    variant="text"
                    startIcon={<TrashFilled />}
                    onClick={(e) => deleteChatHandle(e, ch.chatUuid, index)}
                  >
                    Delete this chat
                  </Button>
                </Stack>
              </TabPanel>
            ))}
          </Box>
        </Box>
      ) : (
        <EmptyStateBox mt="30px">
          <Typography fontSize="32px" fontWeight={700} component="h1" mb={1}>
            Getting started with BrightBot
          </Typography>
          <Typography variant="body1">Launching your chatbot couldn't be easier:</Typography>
          <ul>
            <li>
              Enter your company or solution details in&nbsp;
              <Link to="/settings" component={NavLink}>
                Settings
              </Link>
            </li>
            <li>
              Add&nbsp;
              <Link to="/training" component={NavLink}>
                Training text
              </Link>
              &nbsp;to provide the chatbot with the information you want it to know
            </li>
            <li>
              <Link to="/publish-test" component={NavLink}>
                Publish
              </Link>
              &nbsp;your training text and test your chatbot
            </li>
            <li>
              <Link to="/publish" component={NavLink}>
                Embed
              </Link>
              &nbsp;the chatbot in your website's pages
            </li>
          </ul>
          <p>
            If you need any more help see our{" "}
            <Link href={`https://www.brightbot.app/docs`} target="_blank" sx={{ color: "#1976d2" }}>
              help docs
            </Link>
            &nbsp;or{" "}
            <Link href={`https://www.brightbot.app/contact`} target="_blank" sx={{ color: "#1976d2" }}>
              contact us
            </Link>
            .
          </p>
          <p>Once your chatbot has answered at least one question, you can view and monitor the chats on this page.</p>
        </EmptyStateBox>
      )}
      <Box
        sx={{
          position: { xs: "static", md: "fixed" },
          bottom: 0,
          width: { xs: "100%", md: "calc(100% - 240px)" },
          "& .MuiContainer-root": {
            width: { xs: "calc(100% + 32px)", md: "100%" },
            height: { xs: "auto", md: "65px" },
            py: { xs: "20px", md: 0 },
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default Chats;
