import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuery(process.env.REACT_APP_API_BASE_URL),
  tagTypes: ["TrainingText", "Account", "Chats", "PublishedCount", "UnpublishedCount"],
  endpoints: (builder) => ({
    getAll: builder.query({
      query: ({ value, page, pageSize }) => `api/text/all?page=${page}&size=${pageSize}&searchValue=${value}`,
      providesTags: ["TrainingText"],
    }),
    getAccount: builder.query({
      query: () => "api/account",
      providesTags: ["Account"],
    }),
    getUnpublished: builder.query({
      query: () => "api/text/count-unpublished",
      providesTags: ["UnpublishedCount"],
    }),
    getPublished: builder.query({
      query: () => "api/text/count-published",
      providesTags: ["PublishedCount"],
    }),
    getChatInteractions: builder.query({
      query: () => "api/chat/count-chat-interactions",
      providesTags: ["Chats"],
    }),
    addRecord: builder.mutation({
      query: ({ body }) => ({
        url: "api/text/save",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["TrainingText", "Account", "PublishedCount", "UnpublishedCount"],
    }),
    importCSV: builder.mutation({
      query: ({ file }) => ({
        url: "api/text/import",
        method: "POST",
        body: file,
      }),
      invalidatesTags: ["TrainingText", "Account", "PublishedCount", "UnpublishedCount"],
    }),
    getImportWebsite: builder.query({
      query: (id) => `api/import-from-url/${id}`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data.status === "FINISHED") {
          dispatch(api.util.invalidateTags(["TrainingText", "PublishedCount", "UnpublishedCount"]));
        }
      },
    }),
    importWebsite: builder.mutation({
      query: (body) => ({
        url: "api/import-from-url",
        method: "POST",
        body: body,
      }),
    }),
    postMessage: builder.mutation({
      query: ({ body, chatId }) => ({
        url: `api/chats/${chatId}/messages`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Chats"],
    }),
    updateRecord: builder.mutation({
      query: ({ id, body }) => ({
        url: `api/text/${id}`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["TrainingText"],
    }),
    publishAll: builder.mutation({
      query: ({ body }) => ({
        url: "api/text/publish-all",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["Account", "TrainingText", "PublishedCount", "UnpublishedCount"],
    }),
    updateAccount: builder.mutation({
      query: (body) => ({
        url: "api/account",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["Account"],
    }),
    upgradePlan: builder.mutation({
      query: (body) => ({
        url: "api/account/upgrade/plan",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["Account"],
    }),
    acceptTerms: builder.mutation({
      query: () => ({
        url: "api/account/accept/terms-and-conditions",
        method: "PATCH",
      }),
      invalidatesTags: ["Account"],
    }),
    deleteRecord: builder.mutation({
      query: ({ body }) => ({
        url: "api/text",
        method: "DELETE",
        body: body,
      }),
      invalidatesTags: ["TrainingText"],
    }),
    deleteChat: builder.mutation({
      query: (id) => ({
        url: "api/chat",
        method: "DELETE",
        body: [id],
      }),
      invalidatesTags: ["Chats"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          api.util.updateQueryData("getAllChat", {}, (draft) => {
            draft.chats = draft.chats.filter((c) => c.chatUuid !== id);
          })
        );
      },
    }),
    deleteAccount: builder.mutation({
      query: ({ accountPreffix, accountId }) => ({
        url: `api/auth0/account/${accountPreffix}/${accountId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Account"],
    }),
  }),
});

export const {
  useGetAllQuery,
  useAddRecordMutation,
  useDeleteRecordMutation,
  useUpdateRecordMutation,
  useGetAccountQuery,
  useGetUnpublishedQuery,
  usePublishAllMutation,
  useGetPublishedQuery,
  useDeleteChatMutation,
  useUpdateAccountMutation,
  useImportCSVMutation,
  useUpgradePlanMutation,
  useAcceptTermsMutation,
  useDeleteAccountMutation,
  useGetChatInteractionsQuery,
  usePostMessageMutation,
  useImportWebsiteMutation,
  useGetImportWebsiteQuery,
} = api;
