import { Box, List, Drawer, Stack, Link, Divider } from "@mui/material";
import SidebarLink from "./SidebarLink";
import ProfileMenu from "./ProfileMenu";
import logo from "../../images/logo.svg";
import { ReactComponent as Chats } from "../../images/chats.svg";
import { ReactComponent as Training } from "../../images/training-text.svg";
import { ReactComponent as Publish } from "../../images/publish&test.svg";
import { ReactComponent as Embed } from "../../images/embed.svg";
import { ReactComponent as Settings } from "../../images/settings.svg";
import { ReactComponent as Account } from "../../images/profile-ic.svg";
import { NavLink } from "react-router-dom";

const DrawerInner = () => (
  <Stack height="100%" rowGap="32px" padding="0 16px">
    <Box sx={{ display: "flex", alignItems: "center", padding: "35px 16px 0 16px" }}>
      <Link to="/" component={NavLink}>
        <img src={logo} alt="" />
      </Link>
    </Box>
    <List sx={{ flex: 1 }}>
      <SidebarLink to="/" title="Chats">
        <Chats />
      </SidebarLink>
      <SidebarLink to="/training" title="Training text">
        <Training />
      </SidebarLink>
      <SidebarLink to="/publish-test" title="Publish & Test">
        <Publish />
      </SidebarLink>
      <SidebarLink to="/publish" title="Embed">
        <Embed />
      </SidebarLink>
      <SidebarLink to="/settings" title="Settings">
        <Settings />
      </SidebarLink>
      <Divider sx={{ margin: "16px 0", borderColor: "#DBDBE0" }} />
      <SidebarLink to="/profile" title="My account">
        <Account />
      </SidebarLink>
    </List>
    {/* <ProfileMenu /> */}
  </Stack>
);

const Sidebar = ({ drawerWidth, mobileOpen, handleDrawerToggle }) => {
  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          zIndex: 1301,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#F4F4F6",
            borderRight: "1px solid #DBDBE0",
          },
        }}
      >
        <DrawerInner />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          zIndex: 1301,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#F4F4F6",
            borderRight: "1px solid #DBDBE0",
          },
        }}
        open
      >
        <DrawerInner />
      </Drawer>
    </>
  );
};

export default Sidebar;
