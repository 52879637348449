import { TextField, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Controller } from "react-hook-form";

const FormInputText = ({ name, control, rules, muiProps, label, secondLabel }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, onChange, value }, fieldState }) => {
        return (
          <FormGroup>
            <FormControlLabel
              label={label}
              labelPlacement="top"
              sx={{
                alignItems: "flex-start",
                margin: 0,
                "& .MuiFormControlLabel-label": { color: "#34343D", fontWeight: 600 },
                "& p": { color: "#605F6D" },
              }}
              control={
                <>
                  <TextField
                    error={!!fieldState.error}
                    {...muiProps}
                    helperText={fieldState.error && fieldState.error.message}
                    margin="none"
                    fullWidth
                    variant="outlined"
                    value={value || ""}
                    onChange={onChange}
                    inputRef={ref}
                    sx={{
                      marginTop: "10px",
                      "& p.Mui-error": { position: "absolute", bottom: "-22px", marginLeft: 0 },
                    }}
                  />
                  {secondLabel ? <Typography>{secondLabel}</Typography> : null}
                </>
              }
            />
          </FormGroup>
        );
      }}
    />
  );
};

export default FormInputText;
