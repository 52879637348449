import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import chatApi from "../api/chat";
import { api } from "../api/api";

const chatsAdapter = createEntityAdapter({
  selectId: (chat) => chat.chatUuid,
  sortComparer: (a, b) => b.lastActivityDate - a.lastActivityDate,
});

const slice = createSlice({
  name: "chat",
  initialState: chatsAdapter.getInitialState({ totalPages: 0 }),
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(api.endpoints.getAccount.matchFulfilled, (state, action) => {
      state.emailMeWhenNewChatCreated = action.payload.emailMeWhenNewChatCreated;
    });
    builder.addMatcher(chatApi.endpoints.deleteChat.matchFulfilled, (state, action) => {
      chatsAdapter.removeOne(state, action.meta.arg.originalArgs);
    });
    builder.addMatcher(chatApi.endpoints.getAllChat.matchFulfilled, (state, action) => {
      const hasNewChats = action.payload.chats.some((c) => !state.ids.includes(c.chatUuid));
      const isAutoRefresh = action.meta.arg.originalArgs.isAutoRefresh;
      const isInitialRequest = state.ids.length === 0;
      const shouldPlay = !isInitialRequest && hasNewChats && state.emailMeWhenNewChatCreated && isAutoRefresh;

      chatsAdapter.upsertMany(state, action.payload.chats);
      state.totalPages = action.payload.totalPages;

      if (shouldPlay) {
        new Audio("/Notification.mp3").play();
      }
    });
  },
});

export const chatsSelectors = chatsAdapter.getSelectors((state) => state.chat);
export default slice.reducer;
