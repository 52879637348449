import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import { router } from "./routing";
import { store } from "./store/index";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { InitAuth } from "./components/InitAuth";
import ModalProvider from "mui-modal-provider";

const providerConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  cacheLocation: "localstorage",
  authorizationParams: {
    redirect_uri: window.location.origin + "/",
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: process.env.REACT_APP_AUTH0_SCOPE,
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <InitAuth>
            <ModalProvider>
              <RouterProvider router={router} />
            </ModalProvider>
          </InitAuth>
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
