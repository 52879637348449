import { Box, Divider, Typography, Stack } from "@mui/material";

const HeaderTitle = ({ title, children }) => {
  return (
    <Box mb="32px">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={{ xs: "wrap", sm: "nowrap" }}
        gap={4}
        margin="64px 0 32px"
      >
        <Typography variant="h1" component="h1">
          {title}
        </Typography>
        {children}
      </Stack>
      <Divider sx={{ marginX: { xs: -2, md: -4 }, borderBottom: "1px solid #DBDBE0" }} />
    </Box>
  );
};

export default HeaderTitle;
