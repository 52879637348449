import { useAuth0 } from "@auth0/auth0-react";
import { Box, Stack, Button, Divider, Typography, useTheme, useMediaQuery, Alert } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useGetAccountQuery, useGetChatInteractionsQuery, useGetPublishedQuery } from "../../api/api";
import { useResetPasswordMutation, useGetUserMetadataQuery } from "../../api/auth0ManagementApi";
import Footer from "../../components/Footer";
import HeaderTitle from "../../components/HeaderTitle";
import ProgressBar from "../../components/ProgressBar";
import PlanModal from "./PlanModal";
import { ReactComponent as Avatar } from "../../images/profile-ic.svg";
import { ReactComponent as Training } from "../../images/training-text.svg";
import { ReactComponent as Chats } from "../../images/chats.svg";

const Profile = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { logout, user } = useAuth0();
  const { showModal } = useModal();

  const { data } = useGetAccountQuery();
  const { data: published } = useGetPublishedQuery();
  const { data: chatInteractions } = useGetChatInteractionsQuery();
  const { data: userMetaData } = useGetUserMetadataQuery({ id: user?.sub });
  const [resetPassword, { isSuccess }] = useResetPasswordMutation();

  const canChangePassword = userMetaData?.identities.some((i) => !i.isSocial);

  const changePassword = async () => {
    await resetPassword({
      body: {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: data?.email,
        connection: "Username-Password-Authentication",
      },
    });
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", px: { xs: 2, md: 4 } }}>
      <HeaderTitle title="Your account" />
      {isSuccess ? (
        <Alert severity="success" sx={{ marginBottom: "30px" }}>
          We've just sent you an email to reset your password.
        </Alert>
      ) : null}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={{ xs: "wrap", md: "nowrap" }}
        gap={3}
        maxWidth="1018px"
        sx={{ backgroundColor: "#F4F4F6", borderRadius: "10px", p: 3, mb: 6 }}
      >
        <Stack direction="row" alignItems="center" gap="18px">
          <Box sx={{ display: "flex" }}>
            <Avatar width={36} height={36} stroke="#838391" />
          </Box>
          <Box>
            <Typography variant="body1">Logged in as:</Typography>
            <Typography fontWeight={600} sx={{ color: "#34343D", wordBreak: "break-all" }} component="p">
              {data?.email}
            </Typography>
          </Box>
        </Stack>

        <Stack direction="row" flexWrap="wrap-reverse" gap="12px">
          <Button variant="outlined" onClick={() => logout({ returnTo: window.location.origin })}>
            Log out
          </Button>
          {canChangePassword ? (
            <Button variant="outlined" onClick={changePassword}>
              Change password
            </Button>
          ) : null}
        </Stack>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} gap={{ xs: "40px", lg: "60px" }} mb={4}>
        <Stack maxWidth="500px" width="100%" gap="32px">
          <Typography variant="sectionTitle" component="h2" mb={0}>
            Monthly limits and usage
          </Typography>

          <Stack gap="10px">
            <Stack direction="row" alignItems="center" gap="15px">
              <Training width={16.5} height={10.5} stroke="#6C63FF" />
              <Typography fontWeight={600} sx={{ color: "#34343D" }}>
                Published training text items
              </Typography>
            </Stack>
            <ProgressBar limit={data?.publishedTextItemsLimit} used={published} />
            <Typography>
              Used{" "}
              <Typography fontWeight={600} sx={{ color: "#34343D" }} component="span">
                {published}
              </Typography>{" "}
              of your{" "}
              <Typography fontWeight={600} sx={{ color: "#34343D" }} component="span">
                {data?.publishedTextItemsLimit}
              </Typography>{" "}
              limit
            </Typography>
          </Stack>

          <Stack gap="10px">
            <Stack direction="row" alignItems="center" gap="15px">
              <Chats width={18} height={17.25} stroke="#6C63FF" />
              <Typography fontWeight={600} sx={{ color: "#34343D" }}>
                Chatbot answers
              </Typography>
            </Stack>
            <ProgressBar limit={data?.chatInteractionsLimit} used={chatInteractions} />
            <Typography>
              Used{" "}
              <Typography fontWeight={600} sx={{ color: "#34343D" }} component="span">
                {chatInteractions}
              </Typography>{" "}
              of your{" "}
              <Typography fontWeight={600} sx={{ color: "#34343D" }} component="span">
                {data?.chatInteractionsLimit}
              </Typography>{" "}
              limit
            </Typography>
          </Stack>
        </Stack>

        <Divider orientation={mobile ? "horizontal" : "vertical"} flexItem />

        <Box sx={{ alignSelf: "flex-start", backgroundColor: "#F2F1FD", borderRadius: "10px", p: 3 }}>
          <Typography fontWeight={600} sx={{ color: "#34343D" }}>
            Upgrade your plan
          </Typography>
          <Typography variant="body1">
            If you need more texts or answers per month you can easily change your plan
          </Typography>
          <Button variant="contained" onClick={() => showModal(PlanModal, { email: data?.email })} sx={{ mt: 3 }}>
            Change your plan
          </Button>
        </Box>
      </Stack>

      <Footer />
    </Box>
  );
};

export default Profile;
