import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useUpdateRecordMutation } from "../../api/api";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";

export default function UpdateItemModal(props) {
  const { maxWords, maxCharacters, ...restProps } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [text, setText] = useState(props.text);

  const getWordCount = (value) => value.split(/\s+/).filter(Boolean).length;
  const charCount = text.length;

  const [updateRecord, { isLoading, error }] = useUpdateRecordMutation();

  const handleUpdateClick = async () => {
    try {
      await updateRecord({
        id: props.id,
        body: text,
      });
      props.onClose();
    } catch (err) {}
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const wordCount = getWordCount(value);
    const charLength = value.length;

    if (wordCount <= maxWords && charLength <= maxCharacters) {
      setText(value);
    }
  };

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xs"
      sx={{
        zIndex: 1301,
        "& .MuiPaper-root": { width: "100%", maxWidth: "700px", backgroundColor: "#FAFAFB", borderRadius: "6px", p: 4 },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="sectionTitle" component="h2" mb={0}>
          Update training text
        </Typography>
        <Stack direction="row" gap={3}>
          <Typography variant="body1">
            {getWordCount(text)}/{maxWords}
          </Typography>
          <Typography variant="body1">
            {charCount}/{maxCharacters}
          </Typography>
        </Stack>
      </Stack>
      <form>
        <TextField
          autoFocus
          onChange={handleChange}
          fullWidth
          value={text}
          multiline
          rows={6}
          variant="outlined"
          sx={{ "& .MuiInputBase-root": { p: 3, borderRadius: "6px", borderColor: "#DBDBE0" } }}
        />
        {error && (
          <Typography component="p" sx={{ color: "rgb(211, 47, 47)", fontSize: "14px", marginTop: "5px" }}>
            {error.status} {JSON.stringify(error.data.error)}
          </Typography>
        )}
        <Stack direction="row" alignItems="center" gap="12px" mt={3}>
          <LoadingButton
            type="submit"
            color="success"
            loading={isLoading}
            loadingIndicator="Loading…"
            variant="contained"
            onClick={handleUpdateClick}
            disabled={!text}
            sx={{
              minWidth: "100px",
              "& .MuiLoadingButton-loadingIndicator": {
                color: "#fff",
              },
            }}
          >
            Update
          </LoadingButton>
          <Button variant="text" onClick={props.onClose} sx={{ minWidth: "100px" }}>
            Cancel
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
}
