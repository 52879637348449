import React, { useEffect, useState } from "react";
import { Dialog, useTheme, useMediaQuery, Stack, Button, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useGetImportWebsiteQuery, useImportWebsiteMutation } from "../../api/api";
import { useForm } from "react-hook-form";
import FormInputText from "../../components/FormInputText";

const ImportModal = (props) => {
  const { ...restProps } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { control, handleSubmit, reset } = useForm();
  const [id, setId] = useState();
  const [isCompleted, setIsCompleted] = useState(false);

  const { data, isLoading: isWebsiteLoading } = useGetImportWebsiteQuery(id, {
    pollingInterval: 5000,
    skip: !id || isCompleted,
  });

  const isInProgress = data && data?.status !== "FINISHED";

  const [importWebsite, { isLoading, isError, error }] = useImportWebsiteMutation();

  const onSubmit = async (d) => {
    reset();
    try {
      const result = await importWebsite(d.websiteUrl).unwrap();
      setId(result.id);
    } catch {}
  };

  useEffect(() => {
    if (data?.status === "FINISHED") {
      setIsCompleted(true);
    }
  }, [data]);

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xs"
      sx={{
        zIndex: 1301,
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "635px",
          backgroundColor: "#F4F4F6",
          borderRadius: "10px",
          p: { xs: 3, sm: 6 },
        },
      }}
    >
      <h1>Import from website</h1>
      {!isInProgress ? (
        <Typography mb={3} mt={1}>
          This will import text items by parsing the text content of a website. Note a maximum of 1500 text items will
          be added - get in touch if you need to import more than this. Don’t import from the same website twice as you
          will end up with duplicate text items
        </Typography>
      ) : null}
      {isInProgress ? (
        <>
          <Typography>
            The import is in progress. This could take a few minutes but you won’t see anything update until it has
            finished - so check back in a while.
          </Typography>
          <br />
          <Typography>
            When it has finished, the training text extracted from the website will be shown on the “Training text” page
            (you may need to click refresh). Don’t forget to publish the text so your chatbot can use it.
          </Typography>
        </>
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isInProgress ? (
          <FormInputText
            name="websiteUrl"
            control={control}
            rules={{
              required: "Field can't be empty",
              minLength: {
                value: 3,
                message: "Minimum length 3 character",
              },
            }}
            label="Website address"
          />
        ) : null}
        {isError && (
          <Typography component="p" sx={{ color: "rgb(211, 47, 47)", fontSize: "14px", marginTop: "5px" }}>
            {error.data.message}
          </Typography>
        )}
        <Stack direction="row" alignItems="center" gap="25px" mt={4}>
          {!isInProgress ? (
            <LoadingButton
              type="submit"
              loading={isLoading || isWebsiteLoading || isInProgress}
              loadingIndicator="Importing..."
              variant="contained"
              disabled={isLoading || isWebsiteLoading || isInProgress}
              onClick={handleSubmit}
              sx={{
                minWidth: "100px",
                "& .MuiLoadingButton-loadingIndicator": {
                  color: "#fff",
                },
              }}
            >
              Import
            </LoadingButton>
          ) : null}
          {isInProgress || isCompleted ? (
            <Button variant="contained" onClick={props.onClose} sx={{ minWidth: "100px" }}>
              Close
            </Button>
          ) : null}
        </Stack>
      </form>
    </Dialog>
  );
};

export default ImportModal;
