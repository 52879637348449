import { Typography, Box } from "@mui/material";
import { useGetAccountQuery } from "../../api/api";
import Footer from "../../components/Footer";
import HeaderTitle from "../../components/HeaderTitle";
import CodeBox from "./CodeBox";

const Publish = () => {
  const { data } = useGetAccountQuery();

  const code = `<script>
  (function() {
    window.brightBotWidgetOptions = {
      cdn: "https://widget.brightbot.app/",
      accountToken: "${data?.uuid}",
    };
    const script = document.createElement("script");
    script.src = \`\${window.brightBotWidgetOptions.cdn}js/chat.min.js\`;
    script.async = true;
    document.head.appendChild(script);
  }())
</script>`;

  const codeOptions = `
     window.brightBotWidgetOptions = {
      ... cdn and accountToken values as above ...
      brandPrimaryColor: '#6C63FF',
      brandSecondaryColor: '#8957FF',
      title: 'Your title here',
      subTitle: 'How can I help?',        
      widgetContainer: '#container',
      widgetPosition: 'absolute',
      widgetRight: '50px',
      widgetBottom: '40px',
      };`;

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", px: { xs: 2, md: 4 } }}>
      <HeaderTitle title="Embed" />
      <Typography variant="sectionTitle" component="h2">
        To use your chat bot
      </Typography>
      <Typography variant="body1">Simply copy & paste the following code into every page of your website:</Typography>
      <CodeBox>{code}</CodeBox>
      <Typography variant="body1">
        You can customise the BrightBot widget by adding these extra options to window.brightBotWidgetOptions:
      </Typography>
      <CodeBox>{codeOptions}</CodeBox>
      <Footer />
    </Box>
  );
};

export default Publish;
