import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "../api/api";
import { auth0ManagementApi } from "../api/auth0ManagementApi";
import authReducer from "./authSlice";
import chatReducer from "./chatSlice";
import messageReducer from "./messageSlice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [auth0ManagementApi.reducerPath]: auth0ManagementApi.reducer,
    auth: authReducer,
    chat: chatReducer,
    message: messageReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, auth0ManagementApi.middleware),
});

setupListeners(store.dispatch);
