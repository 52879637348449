import { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Divider,
  Link,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <OutlinedInput
        type="email"
        fullWidth
        placeholder="Email address..."
        sx={{ marginBottom: "12px" }}
      />
      <OutlinedInput
        fullWidth
        type={showPassword ? "text" : "password"}
        placeholder="Password..."
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <Button
        to="/"
        variant="text"
        component={NavLink}
        sx={{
          color: "rgb(73, 0, 133)",
          textDecoration: "none",
          marginTop: "12px",
        }}
      >
        Forgot password?
      </Button>
      <Button
        type="button"
        variant="filled"
        fullWidth
        sx={{ marginTop: "25px" }}
      >
        Sign In
      </Button>
      <Divider
        sx={{
          margin: "8px 0",
          "& span": {
            color: "rgb(53, 52, 61)",
            fontSize: "13px",
            fontWeight: 600,
            letterSpacing: "0.65px",
            padding: "24px",
          },
        }}
      >
        OR
      </Divider>
      <Button
        fullWidth
        variant="outlined"
        sx={{
          border: "none",
          borderRadius: "4px",
          backgroundColor: "#fff",
          fontSize: "12px",
          fontWeight: 600,
          letterSpacing: "0.6px",
          padding: "14px 24px",
          "&:hover": {
            border: "none",
            backgroundColor: "#faf9fa",
          },
        }}
        startIcon={
          <svg
            _ngcontent-vvd-c80=""
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="google-button__icon"
          >
            <g _ngcontent-vvd-c80="" fill="none" fillRule="evenodd">
              <path
                _ngcontent-vvd-c80=""
                d="M23.52 12.273c0-.85-.076-1.67-.218-2.455H12v4.642h6.458a5.522 5.522 0 0 1-2.394 3.622v3.01h3.878c2.27-2.088 3.578-5.165 3.578-8.82z"
                fill="#4285F4"
              ></path>
              <path
                _ngcontent-vvd-c80=""
                d="M12 24c3.24 0 5.956-1.075 7.942-2.907l-3.878-3.01c-1.075.72-2.45 1.144-4.064 1.144-3.125 0-5.77-2.11-6.715-4.947h-4.01v3.11C3.252 21.31 7.31 24 12 24z"
                fill="#34A853"
              ></path>
              <path
                _ngcontent-vvd-c80=""
                d="M5.285 14.28A7.217 7.217 0 0 1 4.909 12c0-.79.134-1.56.374-2.28V6.61h-4.01A12.077 12.077 0 0 0 0 12c0 1.936.464 3.77 1.276 5.39l4.01-3.11z"
                fill="#FBBC05"
              ></path>
              <path
                _ngcontent-vvd-c80=""
                d="M12 4.773c1.762 0 3.344.605 4.587 1.794l3.442-3.442C17.95 1.19 15.233 0 12 0 7.31 0 3.25 2.69 1.276 6.61l4.01 3.11C6.228 6.884 8.876 4.773 12 4.773z"
                fill="#EA4335"
              ></path>
            </g>
          </svg>
        }
      >
        Sign in with google
      </Button>
      <Box textAlign="center">
        <Button
          to="/"
          component={NavLink}
          endIcon={<OpenInNewIcon sx={{ width: "14px" }} />}
          sx={{
            color: "#35343d",
            textDecoration: "none",
            borderRadius: "4px",
            marginTop: "12px",
            padding: "5px 12px",
            "&:hover": {
              textDecoration: "none",
              backgroundColor: "#E8E8EB",
            },
          }}
        >
          How we use cookies
        </Button>
      </Box>
      <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2}>
        <Typography fontSize="14px" fontWeight={600}>
          Not got an account?
        </Typography>
        <Link
          to="/register"
          sx={{ fontSize: "14px", color: "#185bc9", fontWeight: 600 }}
          component={NavLink}
        >
          Sign up
        </Link>
      </Stack>
    </>
  );
};

export default Login;
