import { useState } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = 240;

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Stack minHeight="100vh">
      <Box sx={{ display: "flex", flex: 1 }}>
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
          <Box sx={{ position: "fixed", top: 8, right: 0, zIndex: 1301 }}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Sidebar drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
        </Box>
        <Stack
          justifyContent="space-between"
          component="main"
          sx={{
            position: "relative",
            flexGrow: 1,
            width: { md: `calc(100% - ${drawerWidth}px)` },
            zIndex: 1300,
          }}
        >
          <Outlet />
        </Stack>
      </Box>
    </Stack>
  );
};

export default Layout;
