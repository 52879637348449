import {
  Dialog,
  useTheme,
  useMediaQuery,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Link,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAcceptTermsMutation, useDeleteAccountMutation, useGetAccountQuery } from "../api/api";
import { useForm, Controller } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "./Spinner";

const TermsAndServices = (props) => {
  const { ...restProps } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { control, handleSubmit, watch } = useForm();

  const isChecked = watch("checkbox");

  const { user, logout } = useAuth0();
  const { data, isLoading: isAccountDataLoading } = useGetAccountQuery();
  const [acceptTerms, { isLoading }] = useAcceptTermsMutation();
  const [deleteAccount, { isLoading: isDeleteAccountLoading }] = useDeleteAccountMutation();

  const [accountPreffix, accountId] = user?.sub.split("|");

  const deleteAccountHandle = async () => {
    await deleteAccount({ accountPreffix, accountId });
    logout({ returnTo: window.location.origin });
  };

  if (isAccountDataLoading) {
    return <Spinner />;
  }

  if (isDeleteAccountLoading) {
    return <Spinner />;
  }

  if (data?.isTermsAccepted) {
    return props.children;
  } else {
    return (
      <Dialog
        {...restProps}
        open
        fullScreen={fullScreen}
        maxWidth="xs"
        sx={{
          zIndex: 1301,
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "635px",
            backgroundColor: "#F4F4F6",
            borderRadius: "10px",
            p: { xs: 3, sm: 6 },
          },
        }}
      >
        <h1>Welcome to BrightBot</h1>
        <p>Thanks for trying BrightBot 😊</p>
        <p>
          If you have any questions or feedback about BrightBot we would love to{" "}
          <Link href={`https://www.brightbot.app/contact`} target="_blank" sx={{ color: "#605F6D" }}>
            hear from you.
          </Link>
        </p>
        <p>You just need you to agree to our Ts&Cs and then you can get started.
          They're pretty standard, however it's worth noting that we don't work with companies
          operating in sectors we consider unethical.
          If you think that might affect you then check our{" "}
          <Link href={`https://www.builtbybright.com/csr-policy/`} target="_blank" sx={{ color: "#605F6D" }}>
            CSR policy.
          </Link>
        </p>
        <form onSubmit={handleSubmit(acceptTerms)}>
          <Controller
            name="checkbox"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox />}
                label={
                  <Typography>
                    I accept the{" "}
                    <Link
                      href={`https://www.brightbot.app/resources/BrightBot-terms.pdf`}
                      target="_blank"
                      sx={{ color: "#605F6D" }}
                    >
                      BrightBot Terms &amp; Conditions
                    </Link>
                  </Typography>
                }
              />
            )}
          />

          <Stack direction="row" alignItems="center" gap="25px" mt={3}>
            <LoadingButton
              type="submit"
              loading={isLoading}
              loadingIndicator="Loading…"
              variant="contained"
              disabled={!isChecked}
              onClick={handleSubmit}
              sx={{
                minWidth: "100px",
                "& .MuiLoadingButton-loadingIndicator": {
                  color: "#fff",
                },
              }}
            >
              Accept and get started!
            </LoadingButton>
            <Button variant="text" onClick={deleteAccountHandle} sx={{ minWidth: "100px" }}>
              Cancel (delete account)
            </Button>
          </Stack>
        </form>
      </Dialog>
    );
  }
};

export default TermsAndServices;
