import {
  Box,
  Stack,
  Dialog,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { useUpgradePlanMutation } from "../../api/api";
import LoadingButton from "@mui/lab/LoadingButton";

const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "10px",
  border: "2px solid #fff",
  margin: 0,
  padding: 24,
  alignItems: "flex-start",
  gap: 12,
  "&:hover": {
    backgroundColor: "#F2F1FD",
    borderColor: "#F2F1FD",
    "& .MuiRadio-root svg[data-testid='RadioButtonUncheckedIcon']": {
      color: "#838391",
    },
  },
  "& .MuiRadio-root": {
    padding: 0,
    "& svg[data-testid='RadioButtonUncheckedIcon']": {
      color: "#B6B6BE",
    },
  },
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
  "&:has(span.Mui-checked)": {
    backgroundColor: "#F2F1FD",
    border: "2px solid #6C63FF",
    "& .MuiRadio-root svg[data-testid='RadioButtonUncheckedIcon']": {
      color: "#6C63FF",
    },
  },
}));

export default function FormDialog(props) {
  const { ...restProps } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const plans = {
    starting: {
      title: "starting",
      description: "Training texts: 100/month, Questions: 300/month",
      price: "Free",
      userMessage: "",
    },
    growing: {
      title: "growing",
      description: "Training texts: 1000/month, Questions: 750/month",
      price: "$49 per month",
      userMessage: "",
    },
    custom: {
      title: "pro",
      description: "Training texts: 5000/month, Questions: 2000/month",
      price: "$99 per month",
      userMessage: "",
    },
  };

  const [upgradePlan, { isLoading, isSuccess }] = useUpgradePlanMutation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      plan: "starting",
    },
  });

  const onSubmit = async ({ plan }) => {
    await upgradePlan(plans[plan]);
  };

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xs"
      sx={{
        zIndex: 1301,
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "635px",
          backgroundColor: "#F4F4F6",
          borderRadius: "10px",
          p: { xs: 3, sm: 6 },
        },
      }}
    >
      {isSuccess ? (
        <Box>
          <Typography variant="sectionTitle" component="h2" mb={1}>
            We’ll be in touch!
          </Typography>
          <Typography variant="body1">
            Thanks for changing your plan. We’ll send an email to{" "}
            <Typography color="#34343D" fontWeight={600} component="span">
              {props.email}
            </Typography>{" "}
            to confirm when we've made the changes to update your plan.
          </Typography>
          <Button variant="contained" onClick={props.onClose} sx={{ marginTop: "24px" }}>
            Close
          </Button>
        </Box>
      ) : (
        <>
          <Typography fontWeight={600} sx={{ color: "#34343D" }} component="h2">
            Choose your plan
          </Typography>
          <Typography variant="body1">You can change your plan at any time</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              rules={{ required: true }}
              control={control}
              name="plan"
              render={({ field }) => (
                <RadioGroup {...field} sx={{ gap: "12px", marginTop: 3 }}>
                  <FormControlLabelStyled
                    value="starting"
                    control={<Radio />}
                    label={
                      <Stack direction="row" gap={3} justifyContent="space-between">
                        <Box>
                          <Typography variant="sectionTitle" component="h2" mb="4px">
                            Starting
                          </Typography>
                          <Typography variant="body1">Training texts: 100/month</Typography>
                          <Typography variant="body1">Questions: 300/month</Typography>
                        </Box>
                        <Box>
                          <Typography fontSize="32px" lineHeight="36px" variant="sectionTitle" mb="4px">
                            Free
                          </Typography>
                        </Box>
                      </Stack>
                    }
                  />
                  <FormControlLabelStyled
                    value="growing"
                    control={<Radio />}
                    label={
                      <Stack direction="row" gap={3} justifyContent="space-between">
                        <Box>
                          <Typography variant="sectionTitle" component="h2" mb="4px">
                            Growing
                          </Typography>
                          <Typography variant="body1">Training texts: 500/month</Typography>
                          <Typography variant="body1">Questions: 750/month</Typography>
                        </Box>
                        <Box>
                          <Typography fontSize="32px" lineHeight="36px" variant="sectionTitle" mb="4px">
                            $49
                          </Typography>
                          <Typography variant="body1">/month</Typography>
                        </Box>
                      </Stack>
                    }
                  />
                  <FormControlLabelStyled
                    value="custom"
                    control={<Radio />}
                    label={
                      <Stack direction="row" gap={3} justifyContent="space-between">
                        <Box>
                          <Typography variant="sectionTitle" component="h2" mb="4px">
                            Scaling
                          </Typography>
                          <Typography variant="body1">Training texts: 1500/month</Typography>
                          <Typography variant="body1">Questions: 2000/month</Typography>
                        </Box>
                        <Box>
                          <Typography fontSize="32px" lineHeight="36px" variant="sectionTitle" mb="4px">
                            $99
                          </Typography>
                          <Typography variant="body1">/month</Typography>
                        </Box>
                      </Stack>
                    }
                  />
                </RadioGroup>
              )}
            ></Controller>
            <Stack direction="row" alignItems="center" gap="12px" mt={3}>
              <LoadingButton
                type="submit"
                color="success"
                loading={isLoading}
                loadingIndicator="Loading…"
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  minWidth: "100px",
                  "& .MuiLoadingButton-loadingIndicator": {
                    color: "#fff",
                  },
                }}
              >
                Change plan
              </LoadingButton>
              <Button variant="text" onClick={props.onClose} sx={{ minWidth: "100px" }}>
                Cancel
              </Button>
            </Stack>
          </form>
        </>
      )}
    </Dialog>
  );
}
