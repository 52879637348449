import { useState, useRef } from "react";
import { Box, Stack, TextField, Typography, InputAdornment, IconButton } from "@mui/material";
import { DataGrid, gridClasses, GridToolbarContainer } from "@mui/x-data-grid";
import { useGetAllQuery, useDeleteRecordMutation } from "../../api/api";
import { useModal } from "mui-modal-provider";
import ActionButton from "./ActionButton";
import UpdateItemModal from "./UpdateItemModal";
import HeaderTitle from "../../components/HeaderTitle";
import ClearIcon from "@mui/icons-material/Clear";
import Check from "../../images/check.svg";
import search from "../../images/search.svg";
import { ReactComponent as Trash } from "../../images/trash-filled.svg";
import Footer from "../../components/Footer";
import AddTextBtns from "./AddTextBtns";
import EmptyStateBox from "../../components/EmptyStateBox";

const TrainingText = () => {
  const MAX_WORDS = process.env.REACT_APP_TRAINING_TEXT_WORD_LIMIT;
  const MAX_CHARACTERS = process.env.REACT_APP_TRAINING_TEXT_CHARACTER_LIMIT;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [rowModesModel, setRowModesModel] = useState({});
  const [filteredValue, setFilteredValue] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const inputRef = useRef(null);
  const { showModal } = useModal();

  const { data, isLoading, isFetching } = useGetAllQuery({
    page,
    pageSize,
    value: filteredValue,
  });

  const { data: allData } = useGetAllQuery({
    page,
    pageSize,
    value: "",
  });

  const hasRecords = !allData?.empty;

  const [deleteRecord] = useDeleteRecordMutation();

  const rows = data?.content || [];

  const totalElements = data?.totalElements || 0;
  const showedItemsStart = data?.pageable.offset + 1 || 0;
  const showedItemsEnd =
    (data?.pageable.offset + pageSize > data?.totalElements ? data?.totalElements : data?.pageable.offset + pageSize) ||
    0;

  const columns = [
    { field: "text", minWidth: 100, editable: true, flex: 1 },
    {
      field: "status",
      minWidth: 120,
      renderCell: (params) => (
        <>
          {params.value === "PUBLISHED" ? (
            <Stack direction="row" alignItems="center" columnGap="9px">
              <img src={Check} alt="" />
              <Typography sx={{ textTransform: "capitalize", color: "#605F6D" }}>
                {params.value.toLowerCase()}
              </Typography>
            </Stack>
          ) : null}
        </>
      ),
    },
    {
      field: "actions",
      type: "actions",
      editable: false,
      width: 180,
      getActions: (props) => {
        return [
          <ActionButton variant="outlined" size="small" onClick={() => handleEditClick(props)}>
            Edit
          </ActionButton>,
          <ActionButton variant="outlined" size="small" onClick={() => handleDeleteClick(props.id)}>
            Delete
          </ActionButton>,
        ];
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)", padding: 0, minHeight: "68px" }}>
        {selectionModel.length > 0 ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              width: "100%",
              minHeight: "67px",
              background: "linear-gradient(98.9deg, #8957FF 0%, #6C63FF 100%)",
              px: 3,
            }}
          >
            <Typography variant="body1" sx={{ color: "#fff" }}>
              {selectionModel.length} {selectionModel.length === 1 ? "row" : "rows"} selected
            </Typography>
            <Stack
              direction="row"
              justifyContent="flex-end"
              flexWrap="wrap"
              gap={{ xs: 1, sm: 3 }}
              sx={{ "& > button": { color: "#fff" } }}
            >
              <ActionButton startIcon={<Trash fill="#fff" />} variant="text" onClick={() => handleDeleteSelected()}>
                Delete {selectionModel.length} {selectionModel.length === 1 ? "item" : "items"}
              </ActionButton>
              <ActionButton variant="text" onClick={() => handleDeleteCancel()}>
                Cancel
              </ActionButton>
            </Stack>
          </Stack>
        ) : (
          <Typography variant="body1" padding="15px 24px">
            Showing{" "}
            {totalElements === 0 ? (
              <strong>0</strong>
            ) : (
              <>
                <strong>
                  {" "}
                  {showedItemsStart}-{showedItemsEnd}{" "}
                </strong>{" "}
                of <strong>{totalElements}</strong>
              </>
            )}{" "}
            items
          </Typography>
        )}
      </GridToolbarContainer>
    );
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = ({ id, row }) => {
    showModal(UpdateItemModal, {
      id: id,
      text: row.text,
      maxWords: MAX_WORDS,
      maxCharacters: MAX_CHARACTERS,
    });
  };

  const handleDeleteCancel = async () => {
    setSelectionModel([]);
  };

  const handleDeleteClick = async (id) => {
    await deleteRecord({ body: [id] });
    setSelectionModel([]);
  };

  const handleDeleteSelected = async () => {
    await deleteRecord({ body: selectionModel });
    setSelectionModel([]);
  };

  const handleDeSearch = (e) => {
    e.preventDefault();
    setFilteredValue(inputRef.current.children[0].children[0].value);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box
      sx={{
        height: data?.content.length > 0 ? "94vh" : "100%",
        display: "flex",
        flexDirection: "column",
        px: { xs: 2, md: 4 },
      }}
    >
      <HeaderTitle title="Training text">
        {hasRecords ? (
          <Box component="form" sx={{ maxWidth: "418px", width: "100%", "& .MuiFormControl-root": { width: "100%" } }}>
            <TextField
              placeholder="Filter by keywords"
              variant="outlined"
              ref={inputRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setFilteredValue((inputRef.current.children[0].children[0].value = ""))}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton type="submit" onClick={handleDeSearch} sx={{ marginLeft: "10px" }}>
                      <img src={search} alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& input": { padding: "12px 24px!important", paddingRight: "0!important", fontSize: "16px!important" },
                "& .MuiInputBase-root": { borderRadius: "24px", paddingRight: "34px" },
              }}
            />
          </Box>
        ) : null}
      </HeaderTitle>
      {hasRecords ? (
        <Box sx={{ height: "calc(100% - 250px)", overflow: "auto" }}>
          <DataGrid
            sx={{
              backgroundColor: "#fff",
              overflow: "hidden",
              [`& .${gridClasses.cell}`]: {
                fontSize: "16px",
                color: "#605F6D",
                outline: "none!important",
              },
              "& .MuiDataGrid-columnHeaders": {
                border: "none",
              },
              "& .MuiDataGrid-actionsCell": {
                gridGap: "16px",
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: "#F2F1FD",
                "&:hover": {
                  backgroundColor: "#F2F1FD",
                },
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#F2F1FD",
              },
              "& .MuiDataGrid-main .MuiDataGrid-overlay": {
                height: "124px !important",
              },
            }}
            checkboxSelection
            rowHeight={62}
            editMode="row"
            hideFooterSelectedRowCount
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            onProcessRowUpdateError={(error) => console.error(error)}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            rows={rows}
            columns={columns}
            headerHeight={0}
            autoHeight
            loading={isFetching}
            pageSizeOptions={[]}
            rowsPerPageOptions={[15]}
            rowCount={totalElements}
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            keepNonExistentRowsSelected
            components={{
              Toolbar: CustomToolbar,
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      ) : (
        <EmptyStateBox>
          <Typography variant="sectionTitle" component="h2" mb={2}>
            Start training your bot
          </Typography>
          <Stack gap={3} mb={2}>
            <Typography variant="body1">
              To enable your help bot to answer questions accurately, you need to supply it with training text providing
              it with all the information it needs to answer.
            </Typography>
            <Typography variant="body1">
              You do this by adding training text items. Each one should be a paragraph or two, or no more than 250
              words, providing the context for an answer.
            </Typography>
            <Typography variant="body1">
              For example, if you want your bot to be able to answer questions about your company and team, you could
              add this training text item:
            </Typography>
          </Stack>
          <Box
            sx={{
              background: "linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
              border: "1px solid #E9E9ED",
              borderRadius: "6px",
              p: 2,
              mb: 4,
            }}
          >
            <Typography variant="body1">
              ACME is a growing startup, based in Brighton, UK. We design and produce widgets for retail companies. We
              were founded by Fred Blogs, in 2019. Our team consists of 15 talented consultants. Our customers include
              ABC Limited and DEF PLC.
            </Typography>
          </Box>
          <AddTextBtns maxCharacters={MAX_CHARACTERS} />
        </EmptyStateBox>
      )}
      {hasRecords ? (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            bottom: 0,
            left: -0,
            backgroundColor: "#FAFAFB",
            borderTop: "1px solid #DBDBE0",
            zIndex: 1301,
            p: 4,
            "&::before": {
              content: "''",
              width: "100%",
              height: 16,
              position: "absolute",
              top: -17,
              left: 0,
              background: "linear-gradient(360deg, #FAFAFB 0%, rgba(250, 250, 251, 0) 100%)",
            },
          }}
        >
          <AddTextBtns maxCharacters={MAX_CHARACTERS} />
        </Box>
      ) : null}
      {hasRecords ? null : <Footer />}
    </Box>
  );
};

export default TrainingText;
