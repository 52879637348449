import { api } from "./api";

const chatApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllChat: build.query({
      query: ({ page }) => `api/chat/all?page=${page}`,
      providesTags: ["Chats"],
      async onQueryStarted({ chatId }, { dispatch }) {
        if (chatId) {
          dispatch(
            api.util.prefetch(
              "getChat",
              {
                id: chatId,
                pageNumber: 0,
              },
              { force: true }
            )
          );
        }
      },
    }),
    getChat: build.query({
      query: ({ id, pageNumber }) => `api/chat/${id}?page=${pageNumber}`,
      providesTags: ["Chats"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllChatQuery, useGetChatQuery } = chatApi;
export default chatApi;
