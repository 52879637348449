import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import LoginLayout from "./components/LoginLayout";
import Chats from "./pages/chats";
import Login from "./pages/login";
import Profile from "./pages/profile";
import Publish from "./pages/publish";
import PublishTest from "./pages/publish&Test";
import Register from "./pages/register";
import Settings from "./pages/settings";
import TrainingText from "./pages/trainingText";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        element: <Chats />,
        index: true,
      },
      {
        path: "/training",
        element: <TrainingText />,
      },
      {
        path: "/publish-test",
        element: <PublishTest />,
      },
      {
        path: "/publish",
        element: <Publish />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "/",
    element: <LoginLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
    ],
  },
]);
