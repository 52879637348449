import { Box } from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 24,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E9E9ED",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#6C63FF",
  },
}));

const ProgressBar = ({ limit, used }) => {
  const progressValue = (used * 100) / limit || 0;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={progressValue} />
    </Box>
  );
};

export default ProgressBar;
