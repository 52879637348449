import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Check } from "../../images/check.svg";

const CodeBox = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Box
      onClick={() => {
        navigator.clipboard.writeText(children);
        setIsVisible(true);
      }}
      sx={{
        position: "relative",
        border: "1px solid #DBDBE0",
        borderRadius: "6px",
        backgroundColor: "#fff",
        boxShadow:
          "45px 35px 34px rgba(0, 0, 0, 0.01), 20px 16px 26px rgba(0, 0, 0, 0.02), 5px 4px 14px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02)",
        padding: { xs: 3, md: 4 },
        mb: 4,
        cursor: "pointer",
      }}
      mt={3}
    >
      {isVisible ? (
        <Typography
          color="#2e7d32"
          fontSize="14px"
          position="absolute"
          top={15}
          right={20}
          display="flex"
          alignItems="center"
          gap="5px"
        >
          <Check fill="#2e7d32" style={{ marginBottom: "1px" }} /> Copied to clipboard!
        </Typography>
      ) : null}
      <Typography
        component="pre"
        sx={{ fontFamily: "Inconsolata", whiteSpace: "break-spaces", wordBreak: "break-all", color: "#000" }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default CodeBox;
