import { Container, Stack, Typography, Link } from "@mui/material";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        width: { xs: "calc(100% + 32px)", md: "calc(100% + 64px)" },
        marginLeft: { xs: "-16px", md: "-32px" },
        position: "relative",
        backgroundColor: "#FAFAFB",
        borderTop: "1px solid #DBDBE0",
        zIndex: 1300,
        py: "20px",
        mt: "auto",
      }}
    >
      <Stack
        width="100%"
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
        sx={{ gap: "10px" }}
      >
        <Typography fontSize="14px" textAlign="center">
          All rights reserved Bright Interactive Limited © 2023
        </Typography>
        <Typography fontSize="14px" textAlign="center">
          <FooterLink to="/profile" title="Change my plan" />
        </Typography>
        <Stack direction="row" justifyContent="center" flexWrap="wrap" sx={{ columnGap: "32px", rowGap: 0 }}>
          <FooterLink to="https://www.brightbot.app/resources/BrightBot-terms.pdf" title="Terms & Conditions" />
          <FooterLink to="https://www.builtbybright.com/privacy-policy/" title="Privacy" />
          <FooterLink to="https://www.brightbot.app/cookies" title="Cookies" />
        </Stack>
      </Stack>
    </Container>
  );
};

export default Footer;

const FooterLink = ({ to, title }) => {
  return (
    <Link to={to} target="_blank" underline="always" fontSize="14px" sx={{ color: "#605F6D" }} component={NavLink}>
      {title}
    </Link>
  );
};
