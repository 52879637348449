import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetAccountQuery } from "../../api/api";
import Avatar from "../../images/profile-ic.svg";
import { ReactComponent as Logout } from "../../images/logout.svg";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  zIndex: 1301,
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: "-2px",
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "10px 26px 11px rgba(41, 34, 88, 0.01), 6px 15px 10px rgba(41, 34, 88, 0.03), 2px 7px 7px rgba(41, 34, 88, 0.04), 1px 2px 4px rgba(41, 34, 88, 0.05), 0px 0px 0px rgba(41, 34, 88, 0.05)",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      minHeight: "44px",
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useAuth0();
  const { data } = useGetAccountQuery();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? "profile-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        fullWidth
        variant="profileMenu"
        disableElevation
        onClick={handleClick}
        startIcon={<img src={Avatar} alt="" />}
        sx={{
          color: "#605F6D",
          textTransform: "none",
          textDecoration: "none",
          padding: "11.5px 5px",
          border: "none",
          borderRadius: "6px",
          marginBottom: "12px",
          "& span": { flexShrink: 0, marginRight: "15px" },
          "&:hover": { backgroundColor: "#FAFAFB", textDecoration: "none" },
        }}
      >
        <Typography fontSize="14px" sx={{ width: "128px", textOverflow: "ellipsis", overflow: "hidden" }}>
          {data?.email}
        </Typography>
      </Button>
      <StyledMenu id="profile-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => logout({ returnTo: window.location.origin })}
          sx={{ color: "#3F35E9", fontWeight: 600, gap: "12px" }}
        >
          <Logout />
          Log out
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
