import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import User from "../../images/user.svg";
import Admin from "../../images/blue-user.svg";
import System from "../../images/system.svg";
import TailLeft from "../../images/tail-left.svg";
import TailRight from "../../images/tail-right.svg";
import { useSelector } from "react-redux";
import { messageSelectors } from "../../store/messageSlice";

const Messages = (props) => {
  const messages = useSelector(messageSelectors.selectAll).filter((m) => m.chatUuid === props.activeChatId);

  return (
    <>
      {messages.map((message) => (
        <Box key={message.id}>
          <Stack position="relative" direction="row" alignItems="center" px={8}>
            <Box
              sx={{
                position: "absolute",
                left: message.role === "USER" ? 0 : "initial",
                right: message.role !== "USER" ? 0 : "initial",
                bottom: -16,
              }}
            >
              {message.role === "USER" ? (
                <img src={User} alt="" />
              ) : message.role === "ASSISTANT" ? (
                <img src={System} alt="" />
              ) : (
                <img src={Admin} alt="" />
              )}
            </Box>
            <Box
              sx={{
                position: "relative",
                flex: 1,
                background: message.role === "USER" ? "#F4F4F6" : "linear-gradient(98.9deg, #8957FF 0%, #6C63FF 100%)",
                borderRadius: message.role === "USER" ? "6px 6px 6px 0px" : "6px 6px 0px 6px",
                padding: "24px 24px 16px",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  left: message.role === "USER" ? "-20px" : "initial",
                  right: message.role !== "USER" ? "-20px" : "initial",
                  bottom: 0,
                  backgroundImage: message.role === "USER" ? `url(${TailLeft})` : `url(${TailRight})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                  width: 20,
                  height: 24,
                },
              }}
            >
              <Typography variant="body1" sx={{ color: message.role === "USER" ? "#605F6D" : "#fff" }}>
                {message.text}
              </Typography>
              <Typography
                fontSize="14px"
                mt="12px"
                color={message.role === "USER" ? "#838391" : "#F2F1FD"}
                textAlign={message.role === "USER" ? "left" : "right"}
                sx={{ opacity: message.role === "USER" ? 1 : 0.9 }}
              >
                {message.timeSinceWasCreated}
              </Typography>
            </Box>
          </Stack>
        </Box>
      ))}
    </>
  );
};

export default Messages;
