import { useEffect } from "react";
import { Box, Stack, Typography, CircularProgress, Alert, Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { NavLink } from "react-router-dom";
import { useGetUnpublishedQuery, usePublishAllMutation, useGetAccountQuery, useGetPublishedQuery } from "../../api/api";
import HeaderTitle from "../../components/HeaderTitle";
import globe from "../../images/globe.svg";
import Footer from "../../components/Footer";

const PublishTest = () => {
  const { data: unpublished, isLoading: isUnpublishedLoading } = useGetUnpublishedQuery();
  const { data: published } = useGetPublishedQuery();
  const { data: accountData, isLoading: isAccountDataLoading } = useGetAccountQuery();

  const [publishAll, { isLoading, isError, error }] = usePublishAllMutation();

  const isAccountUpdated = !!accountData?.companyName && !!accountData?.websiteUrl && !!accountData?.helpEmail;

  const handleClick = async () => {
    await publishAll({
      body: unpublished,
    });
  };

  useEffect(() => {
    if (!accountData?.uuid) {
      return;
    }

    if (!window.botInitialized) {
      window.botInitialized = true;
      (function () {
        window.brightBotWidgetOptions = {
          cdn: "https://widget.brightbot.app/",
          accountToken: accountData?.uuid,
          widgetPosition: "absolute",
          widgetRight: "50px",
          widgetBottom: "90px",
        };
        const script = document.createElement("script");
        script.src = `${window.brightBotWidgetOptions.cdn}js/chat.min.js`;
        script.async = true;
        document.head.appendChild(script);
      })();
    } else {
      if (document.querySelector("bright-bot")) {
        document.querySelector("bright-bot").style.display = "block";
      }
    }

    return () => {
      if (document.querySelector("bright-bot")) {
        document.querySelector("bright-bot").style.display = "none";
      }
    };
  }, [accountData?.uuid]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", px: { xs: 2, md: 4 } }}>
      <HeaderTitle title="Publish & test" />
      {!isAccountUpdated && !isAccountDataLoading ? (
        <Alert severity="info" sx={{ marginBottom: "30px" }}>
          <Typography fontSize="17px">
            You have not completed your settings, so the chat bot does not know which company or solution it represents.{" "}
            <Link to="/settings" component={NavLink}>
              Please enter these now
            </Link>
          </Typography>
        </Alert>
      ) : null}

      <Box maxWidth="736px">
        <Typography variant="body1" mb={3}>
          You have <b>{unpublished}</b> unpublished training texts. You’ve published <b>{published} </b>
          of your <b>{accountData?.publishedTextItemsLimit}</b> monthly limit.
        </Typography>
        <Typography variant="body1" mb={3}>
          Once you've published your training texts, click in the bottom right to open the chatbot to test it.
        </Typography>
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap="15px" mb="30px">
          <LoadingButton
            variant="contained"
            startIcon={<img src={globe} alt="" />}
            disabled={!isAccountUpdated}
            onClick={handleClick}
            loading={isUnpublishedLoading}
          >
            Publish {unpublished === 0 ? null : unpublished === 1 ? `${unpublished} item` : `${unpublished} items`}
          </LoadingButton>
          {isError ? (
            <Stack direction="row">
              <Alert severity="warning">{error.data.message}</Alert>
            </Stack>
          ) : null}
        </Stack>
      </Box>
      {isLoading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: "50%",
            left: 0,
            transform: "translateY(-50%)",
            backgroundColor: "rgba(255,255,255,0.5)",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : null}
      <Footer />
    </Box>
  );
};

export default PublishTest;
